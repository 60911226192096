import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Home',
	component: () => import('../views/Home.vue'),
	meta: {}
}, {
	path: '/form',
	name: 'Form',
	component: () => import('../views/form.vue'),
	meta: {
		title: '上传文档'
	}
}, {
	path: '/result',
	name: 'Result',
	component: () => import('../views/result.vue'),
	meta: {}
}, {
	path: '/mfg',
	name: 'mobileForeground',
	component: () => import('../views/mobileForeground.vue'),
	meta: {}
}]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	// 让页面回到顶部
	document.documentElement.scrollTop = 0
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	if (to.name != 'nopath') {
		if (to.meta.title) {
			document.title = `绿洲Chat-${to.meta.title}`;
		} else {
			document.title = `绿洲Chat`;
		}
		next()
	}
});

export default router